@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&family=Montserrat:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles:wght@400;700&display=swap');

@font-face {
  font-family: 'Fuzzy Bubbles';
  src: url('../fonts//FuzzyBubbles-Regular.eot');
  src: url('../fonts/FuzzyBubbles-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/FuzzyBubbles-Regular.woff2') format('woff2'),
    url('../fonts/FuzzyBubbles-Regular.woff') format('woff'),
    url('../fonts/FuzzyBubbles-Regular.ttf') format('truetype'),
    url('../fonts/FuzzyBubbles-Regular.svg#FuzzyBubbles-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  outline: none;
}

body {
  font-family: 'Fuzzy Bubbles', cursive !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Fuzzy Bubbles', cursive !important;
}

.home {
  background-image: url(../images/main_bg.jpg);
  background-attachment: fixed;
  background-size: 100%;
}

.character1 {
  position: absolute;
  left: 5%;
  bottom: 12%;
  width: 10%;
}

.character1 img {
  width: 100%;
}

.character2 {
  position: absolute;
  right: 18%;
  bottom: 30%;
  width: 100px;
}

.character2 img {
  width: 100%;
}

.character3 {
  position: absolute;
  right: 4%;
  bottom: 7%;
  width: 10%;
}

.character3 img {
  width: 100%;
}

.w100 {
  width: 100%;
}

.active {
  color: #1675e0;
}

.auto_block {
  width: 54%;
  height: 100vh;
  padding: 2% 5% 3% 10%;
  margin: 0 auto;
  background-image: url(../images/auto.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  position: relative;
}

.auto_block .post_block {
  border-radius: 10px;
  background-color: #ffffff;
  border: solid 4px #4b2d23;
  height: 100%;
}

.auto_block .post_block .post_image {
  height: 75%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
}

.auto_block .post_block .post_image img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.post_actions {
  width: 100%;
  margin-top: 10px;
}

.post_profile {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.post_profile .profile_pic {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.post_profile .profile_pic img {
  border-radius: 100%;
  width: 100%;
}

.post_profile h3 {
  margin: 0px;
  font-size: 13px;
  line-height: 18px;
  font-weight: bold;
  color: #666;
}

.post_actions .post_likeblock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.post_likeblock .like {
  display: flex;
  align-items: center;
  margin-right: 13px;
}

.post_likeblock .like .img {
  width: 20px;
  margin-right: 5px;
}

.post_likeblock .like .text {
  font-size: 13px;
  color: #666;
  font-weight: normal;
}

.post_likeblock .comments a.text {
  color: #0095f6 !important;
  text-decoration: none;
  font-weight: normal;
}

.post_likeblock .comments a.text:hover {
  text-decoration: underline;
}

.post_likeblock .comments {
  display: flex;
  align-items: center;
}

.post_likeblock .comments .text {
  font-size: 13px;
}

.post_likeblock .img {
  width: 20px;
  margin-right: 5px;
}

.post_comment {
  width: 100%;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  position: relative;
}

.post_comment textarea {
  padding: 12px;
  font-size: 13px;
  background-color: #e1e4e9;
  color: #4d3025;
  border-radius: 3px 0px 0px 3px;
  border: none;
  height: 50px;
  width: 100%;
  line-height: 15px;
}

.post_commentut textarea {

  height: 70px !important;

}

.loaderzindex {
  /* position: absolute; */
  z-index: 100;
}

.btnattach {
  position: absolute;
  right: 40px;
  bottom: 0px;
  padding: 8px 12px;
  color: #700124;
  border: none;
  font-size: 15px;
}

.post_comment .btn {
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 8px 12px;
  color: #0095f6 !important;
  border: none;
  font-size: 15px;
}

.post_comment .btn:focus {
  box-shadow: none;
}

.post_comment .btn img {
  width: 25px;
  margin-right: 5px;
}


.post_details {
  width: 100%;
  display: block;
}

.post_title {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  color: #1f0d0d;
}

/* .post_item {height: 100%; margin-bottom: 20px; border-bottom: solid 1px #c8c8c8; padding-bottom: 35px;} */
.post_item {
  margin-bottom: 15px;
  border-bottom: solid 1px #c8c8c8;
  padding-bottom: 10px;
}

.auto_block .card_wrp::-webkit-scrollbar {
  width: 15px;
}

.auto_block .card_wrp::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.auto_block .card_wrp::-webkit-scrollbar-thumb {
  background: rgb(172, 172, 172);
}

.auto_block .card_wrp::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.post_comment textarea::-webkit-input-placeholder {
  color: #8d7f78;
}

.post_comment textarea:-ms-input-placeholder {
  color: #8d7f78;
}

.post_comment textarea::placeholder {
  color: #8d7f78;
}

.post_hed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: solid 1px #c8c8c8;
}

.post_logo {
  width: 70px;
  margin-top: -35px;
  position: absolute;
  transform: rotate(349deg);
  margin-left: 10px;
  
}

.card_wrp {
  height: calc(100% - 84px);
  overflow: auto;
  padding: 15px;
  overflow-x: hidden;
}

.post_logo img {
  width: 100%;
}

.hed_link {
  font-size: 12px;
  margin: 0 5px;
  color: #333333 !important;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #d5d6d8;
  padding: 3px 10px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.hed_link .icon {
  width: 25px;
  margin-right: 5px;
}

.hed_link .icon img {
  width: 25px;
}

.help_icon {
  width: 25px;
  height: 25px;
}

.dropdown-toggle {
  display: flex;
}

.home_profile {
  display: flex;
  align-items: center;
}

.container {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 5px;
}

.help_img {
  max-width: 40px;
  max-height: 40px;
}

.help_text {
  width: 90%;
}

.hed_link:hover {
  text-decoration: none;
  background-color: #ccc;
}

.login_bg {
  /* background-image: url(../images/login_bg.jpg); */
  /* background-attachment: fixed; 
   background-size: 100%; */
  background-color: #1f0c0c;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.clock {
  position: absolute;
  top: 10%;
  right: 7%;
  width: 8%;
  display: none;
}

.clock img {
  width: 100%;
}

.plant {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 8%;
}

.plant img {
  width: 100%;
}

.login_form {
  width: 1000px;
  height: 648px;
  /* margin: 0 auto; */
  /* margin-top: 8vh; */
  background-image: url(../images/login_form_bg.png);
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}

.login_form input {
  border: none;
  background: none;
  font-size: 16px;
  color: #c17435;
}

.login_form .username {
  width: 140px;
  left: 353px;
  top: 242px;
  position: absolute;
}

.login_form .password {
  width: 140px;
  left: 408px;
  top: 385px;
  position: absolute;
}

.login_form .login_btn {
  position: absolute;
  width: 8.5%;
  left: 456px;
  top: 448px;
  color: #447080;
  font-size: 24px;
  text-align: left;
  padding-left: 0.6em;
}

.login_form .forgotpassword {
  position: absolute;
  width: 93px;
  left: 480px;
  top: 508px;
  color: #ffc1c8 !important;
  font-size: 12px;
  text-decoration: none;
}

.login_form .signup_btn {
  font-family: 'Fuzzy Bubbles', cursive !important;
  position: absolute;
  width: 5%;
  right: 39%;
  top: 58px;
  color: #d12b5f !important;
  font-size: 24px;
  text-decoration: none;
}

.login_form .signup_btn:hover {
  color: #b83b62;
}

.login_form .forgotpassword:hover {
  color: #fc374b;
}

.login_form .login_btn:hover {
  color: #004658;
}

/* .login_form input::-webkit-input-placeholder { color: #c17435;  font-family: 'Gochi Hand', cursive; font-size: 18px;  }
.login_form input:-ms-input-placeholder {color: #c17435;  font-family: 'Gochi Hand', cursive; text-align: center; }
.login_form input::placeholder { color: #c17435;  font-family: 'Gochi Hand', cursive; text-align: center; } */
.forgotpassword_popup img {
  width: 100%;
}

.forgotpassword_popup .modal-content {
  background-color: #e0d9d3;
}

.forgotpassword_popup .popup_close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 99;
  color: #1a0e0d;
}

.forgotpassword_popup .row {
  display: flex;
  align-items: center;
}

.forgotpassword_popup .col-6 {
  padding: 35px;
}

.forgotpassword_popup .form-control {
  background-color: #bbb8b6;
  border-radius: 2px;
  padding: 8px 15px;
  border: none;
}

.forgotpassword_popup .btn {
  background-color: #ff725e;
  border-radius: 2px;
  padding: 8px 15px;
  border: none;
}

.forgotpassword_popup .form-control::-webkit-input-placeholder {
  color: #2c1213cc;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-style: italic;
}

.forgotpassword_popup .form-control:-ms-input-placeholder {
  color: #2c1213cc;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-style: italic;
}

.forgotpassword_popup .form-control::placeholder {
  color: #2c1213cc;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-style: italic;
}

a {
  cursor: pointer;
}

.signup_form {
  width: 1000px;
  height: 648px;
  /* margin: 0 auto; */
  /* margin-top: 8vh; */
  background-image: url(../images/signup_form_bg.png);
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}

.signup_form input {
  border: none;
  background: none;
  font-size: 16px;
  color: #c17435;
}

.signup_form .fullname {
  width: 140px;
  left: 344px;
  top: 153px;
  position: absolute;
}

.signup_form .email {
  width: 140px;
  left: 449px;
  top: 245px;
  text-align: center;
  position: absolute;
}

.signup_form .username {
  width: 142px;
  left: 289px;
  top: 288px;
  font-size: 16px;
  position: absolute;
}

.signup_form .password {
  width: 140px;
  left: 453px;
  top: 334px;
  position: absolute;
}

.signup_form .confirmpassword {
  width: 140px;
  left: 295px;
  top: 385px;
  position: absolute;
}

.signup_form .avatar {
  width: 116px;
  height: 110px;
  left: 464px;
  top: 413px;
  position: absolute;
  font-size: 0px;
}

.avatar>div {
  position: relative;
}

.avatar>div>div {
  border: none !important;
}

.avatar label {
  width: 116px;
  height: 110px;
}

.signup_form input[type="file"] {
  display: none;
}

.signup_form .custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.signup_form .login_btn {
  width: 8.5%;
  left: 371px;
  top: 540px;
  color: #447080;
  font-size: 22px;
  text-align: left;
  padding: 0;
  position: absolute;
}

.signup_form .forgotpassword {
  position: absolute;
  width: 72px;
  left: 366px;
  top: 573px;
  color: #d12739;
  font-size: 10px;
  text-decoration: none;
}

.signup_form .signup_btn {
  position: absolute;
  width: 80px;
  right: 369px;
  top: 60px;
  color: #4e91ab !important;
  font-size: 24px;
  text-decoration: none;
}

.signup_form .signup_btn:hover {
  color: #3a6675;
}

.signup_form .forgotpassword:hover {
  color: #fc374b;
}

.signup_form .login_btn:hover {
  color: #004658;
}

/* .signup_form input::-webkit-input-placeholder { color: #c17435;  font-family: 'Gochi Hand', cursive; font-size: 18px;  }
.signup_form input:-ms-input-placeholder {color: #c17435;  font-family: 'Gochi Hand', cursive; text-align: center; }
.signup_form input::placeholder { color: #c17435;  font-family: 'Gochi Hand', cursive; text-align: center; } */
.signup_text {
  width: 142px;
  left: 335px;
  top: 54px;
  position: absolute;
  color: #b33d50;
  font-family: 'Fuzzy Bubbles', cursive !important;
  font-size: 22px;
}

.signup_form .username::-webkit-input-placeholder {
  color: #c17435;
  font-size: 16px;
}

.signup_form .username:-ms-input-placeholder {
  color: #c17435;
  text-align: center;
}

.signup_form .username::placeholder {
  color: #c17435;
  text-align: center;
}

.comments_popup {
  background: #000000d9;
}

.comments_popup .modal-content {
  background-color: #fff;
}

.comments_popup .popup_close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 99;
  color: #fff !important;
}

.comments_popup .popup_close .material-icons {
  font-size: 36px;
}

.comments_popup .post_img_block {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
}

.comments_popup .post_img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.comments_popup .comment_wrp {
  display: flex;
  width: 100%;
}

.comments_popup .comment_wrp .comment_details {
  max-height: 92vh;
  min-width: 450px;
}

.comments_popup .modal-body {
  padding: 0px;
}

.comments_popup .post_options {
  position: absolute;
  right: 15px;
  top: 15px;
}

.comments_popup .post_options .material-icons {
  color: #c17435;
}

.comments_popup .post_profile {
  border-bottom: solid 1px #efefef;
  padding: 10px 15px;
}

.comments_popup .comment_wrp .comment_lists {
  padding: 15px 15px;
  width: 100%;
  height: calc(100vh - 313px);
  overflow: auto;
}

.comments_popup .comment_wrp .comment_list .post_profile {
  border: none;
  padding: 0px;
}

.comments_popup .comment {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
}

.comments_popup .comment_list .post_profile {
  align-items: flex-start;
}

.comments_popup .comment_list .post_title {
  width: calc(100% - 45px);
}

.comments_popup .comment_actions {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.cmspage {
  overflow: auto !important;
}

.comments_popup .comment_actions .cmt_act {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-right: 12px;
  color: #8e8e8e;
  text-decoration: none;
}

.comments_popup .comment_actions .cmt_act2 {
  font-size: 12px;
  display: inline-flex;
  font-weight: bold;
  line-height: 16px;
  margin-right: 12px;
  color: #8e8e8e;
  text-decoration: none;
}

.comments_popup .comment_actions .cmt_act2 .material-icons {
  font-size: 15px;
  margin-left: 5px;
}

.comments_popup .post_share .material-icons {
  font-size: 18px;
}

.comments_popup .comment_actions .cmt_act:hover {
  color: #757575;
}

.comments_popup .comment_actions .cmt_act2:hover {
  color: #757575;
}

.comments_popup .rep_line {
  height: 1px;
  width: 25px;
  margin-right: 15px;
  background-color: #8e8e8e;
}

.comments_popup .post_comment {
  border-top: 1px solid #efefef;
  width: 100%;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  margin-top: 0;
}

.comments_popup .post_comment .post_btn {
  background: none;
  width: 40px;
  border: none;
  font-size: 14px;
  line-height: 18px;
  color: #0095f6;
  font-weight: 600;
  padding: 0px;
}

.comments_popup .comment_input {
  background-color: transparent !important;
  padding: 0 !important;
  font-size: 14px;
}

.comments_popup .post_share {
  border-top: 1px solid #efefef;
  width: 100%;
  padding: 15px 15px;
}

.comments_popup .post_share .icons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.comments_popup .post_share p {
  margin: 5px 0;
}

.comments_popup .post_share p a {
  font-size: 14px;
  line-height: 18px;
  color: #262626;
  font-weight: 600;
  text-decoration: none;
}

.comments_popup .post_icon {
  text-decoration: none;
  color: #262626;
  margin-right: 10px;
}

.comments_popup .post_icon:hover {
  color: #555555;
}

.comments_popup .post_icon2 {
  color: #262626;
}

.comments_popup .post_icon2:hover {
  color: #555555;
}

.comments_popup .post_share .comment_actions {
  margin: 0px !important
}

.comments_popup .comment_wrp .comment_lists::-webkit-scrollbar {
  width: 5px;
}

.comments_popup .comment_wrp .comment_lists::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.comments_popup .comment_wrp .comment_lists::-webkit-scrollbar-thumb {
  background: #888;
}

.comments_popup .comment_wrp .comment_lists::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .profile .dropdown-toggle::after { cursor: pointer;  position: absolute;  left: 0.5vh;  bottom: -1.52vh; color: #c17435;     border-top: 1vh solid;
  border-right: 1vh solid transparent;
  border-bottom: 0;
  border-left: 1vh solid transparent; } */
.trending {
  position: absolute;
  top: 2.5vh;
  right: 135px;
  font-size: 24px;
  text-decoration: none;
  padding: 0 20px;
  border-radius: 5px;
  color: #f9f9f9;
  background-color: #6d2626;
}

.trending:hover,
.trending:focus {
  background-color: #9c3838;
  text-decoration: none;
  color: white
}

.trending img {
  width: 20px;
  margin-right: 5px;
}

.profile {
  font-size: 30px;
  text-decoration: none;
  z-index: 99;
}

.profile img {
  width: 100%;
}

.blink {
  border-radius: 50px;
  width: 5%;
  height: 5%;
}

.blink:before,
.blink:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e91e63;
  border-radius: 50px;
  opacity: 0;
  z-index: -1;
}

.blink:before {
  transform: scale(1);
  animation: blink 1.6s infinite linear;
}

.blink:after {
  animation: blink 1.6s 1.2s infinite linear;
}

.footer {
  width: 100%;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  background: rgb(2, 0, 36);
  background: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 0) 0%, rgba(0, 0, 0, 1) 100%);
  padding: 10px;
}

.footer p {
  font-size: 13px;
  color: #fff;
  margin: 0px;
}

.footer ul {
  margin: 0px;
  padding: 0px;
}

.footer ul li {
  list-style: none;
  display: inline-block;
  margin-left: 20px;
}

.footer ul li a {
  font-size: 14px;
  text-decoration: none;
  color: #fff !important;
}

.footer ul li a:hover {
  text-decoration: underline;
}

.addmeme_footer {
  position: relative;
}

input:-webkit-autofill {
  border: none;
  border-radius: 0.3rem;
  caret-color: #900230;
  color: #8b0331;
  background: #ffcc57;
  -webkit-text-fill-color: #900230;
  -webkit-background-clip: text;
  box-shadow: 0 0 0 50px #ffcc57 inset;
}

@keyframes blink {
  0% {
    opacity: 0;
    transform: scale(0.4);
  }

  33.33333% {
    opacity: 0.5;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(2);
  }
}




@media (max-width:1000px) {
  .meme_header .wrapper {
    width: 100% !important;
  }

  .hed_link .icon {
    display: none;
  }

  .tui-image-editor-container {
    width: 100% !important;
  }

  .tui-image-editor-container .tui-image-editor-header {
    min-width: 100% !important;
  }

  .tui-image-editor-container .tui-image-editor-header-buttons div {
    width: 90px !important;
    height: 30px !important;
    line-height: 30px !important;
  }

  .tui-image-editor-container .tui-image-editor-header-buttons button {
    width: 90px !important;
    height: 30px !important;
    line-height: 30px !important;
  }

  .content .content_logo {
    width: 100px !important;
    margin-top: 0px;
  }

  .login_bg {
    background-size: cover !important;
    background-attachment: inherit;
    background-position: center;
    background-repeat: no-repeat;
  }

  .signup_form {
    background-image: url(../images/mob_bg.jpg) !important;
    padding-bottom: 50px;
    background-size: cover;
    overflow: auto;
    padding-top: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: top center;
    height: 100%;
  }

  .post_title {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .post_details {
    width: calc(100% - 150px);
  }

  .post_likeblock .comments .text {
    font-size: 12px;
  }

  .profile .dropdown-menu {
    right: 0px !important;
  }

  .profile .dropdown-toggle::after {
    display: none;
  }

  .blink:before,
  .blink:after {
    display: none;
  }

  .post_logo {
    width: 50px;
    margin-left: 0px;
  }

  .hed_link {
    padding: 4px 8px;
  }

  .trending {
    top: 10px;
    left: 10px;
    width: 151px;
    text-align: center;
    position: fixed;
  }

  .profile {
    width: 40px !important;
    height: 40px !important;
  }

  .profile .dropdown {
    display: inline-block;
  }

  .profile img {
    width: 40px;
    height: 40px;
  }

  .comments_popup .post_img_block {
    width: 100%;
  }

  .comments_popup .comment_wrp .comment_details {
    width: 100%;
  }

  .comments_popup .comment_wrp {
    flex-direction: column;
  }

  .home {
    height: 100vh;
    display: flex;
    align-items: center;
  }

  .post_likeblock .img {
    width: 14px;
    margin-right: 5px;
    cursor: pointer;
  }

  .character1 {
    left: 2%;
    display: none;
  }

  .character3 {
    right: 0%;
    bottom: 0%;
    width: 120px;
    display: none;
  }

  .character2 {
    display: none;
  }

  .auto_block .post_block {
    width: 100%;
  }

  .card_wrp {
    padding: 10px;
    padding-top: 10px;
    height: 87%;
  }

  .post_profile {
    margin-bottom: 5px;
  }

  .post_comment .btn img {
    width: 20px;
  }

  .post_comment .btn {
    padding: 5px 6px;
    width: 50px;
    font-size: 14px;
  }

  .post_comment textarea {
    width: 100%;
    padding: 8px;
    line-height: 15px;
    border-radius: 3px 0px 0px 3px;
  }

  .post_likeblock .like .img {
    width: 12px;
    cursor: pointer;
  }

  .post_likeblock .img {
    width: 14px;
  }

  .auto_block {
    width: 100%;
    height: 100vh;
    padding: 20px 15px 30px 15px;
    background-size: 100vh;
    background-position: 60% 0px;
  }

  .footer {
    display: none;
  }

  .auto_block .post_block .post_image {
    height: 300px;
  }

  .post_profile .profile_pic {
    width: 30px;
    height: 30px;
    margin-right: 6px;
  }

  .comments_popup .comment_wrp .comment_lists {
    display: none;
  }

  .comments_popup .post_share {
    border-top: 0 solid #efefef;
    padding-top: 0;
  }

  .comments_popup .popup_close .material-icons {
    font-size: 28px;
    cursor: pointer;
  }

  .comments_popup .post_profile {
    padding: 10px 10px;
  }

  .post_icon .material-icons {
    font-size: 20px;
    cursor: pointer;
  }

  .signup_text {
    width: auto;
    left: 20px;
    top: 10px;
    font-size: 16px;
    display: none;
  }

  .signup_form {
    background-image: url(../images/mob_bg.jpg) !important;
    background-size: cover;
    background-position: top center;
    height: 100%;
  }

  .signup_form .signup_btn {
    width: auto;
    right: 20px;
    top: 0px;
    font-size: 16px;
    background: none;
    padding: 0;
  }

  .signup_form input {
    background-image: url(../images/form_field_bg.png);
    background-repeat: no-repeat;
    padding: 25px 6px 15px 13px;
    width: 300px;
    height: 70px;
    background-size: 100%;
  }

  .signup_form .fullname {
    margin-bottom: 20px;
    left: 20px;
    top: 92px;
    width: 300px;
    position: static;
  }

  .signup_form .username {
    margin-bottom: 20px;
    left: inherit;
    right: 20px;
    top: 170px;
    width: 300px;
    position: static;
  }

  .signup_form .password {
    margin-bottom: 20px;
    left: inherit;
    top: 320px;
    right: 20px;
    width: 300px;
    position: static;
  }

  .signup_form .confirmpassword {
    margin-bottom: 20px;
    left: 20px;
    top: 401px;
    width: 300px;
    position: static;
  }

  .signup_form .avatar {
    margin-bottom: 45px;
    left: inherit;
    right: 20px;
    top: 463px;
    background-image: url(../images/form_upload_bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 114px;
    border: none;
    position: static;
  }

  .signup_form .login_btn {
    margin-bottom: 20px;
    position: static;
    width: 190px;
    left: 20px;
    padding: 8px 5px 3px 36px;
    background-image: url(../images/login_btn_bg.png);
    top: 572px;
    z-index: 99;
  }

  .signup_form .email {
    margin-bottom: 20px;
    position: static;
    left: 20px;
    top: 246px;
    width: 300px;
    z-index: 99;
  }

  .login_form {
    width: 100%;
    padding: 0 15px;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: top center;
    height: 100%;
    background-image: url(../images/mob_bg.jpg);
  }

  .login_form::after {
    content: "";
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.705);
    z-index: 9;
    top: 0px;
  }

  .login_form .signup_btn {
    width: auto;
    right: 20px;
    top: 10px;
    font-size: 16px;
    z-index: 99;
  }

  .login_form input {
    background-image: url(../images/form_field_bg.png);
    background-repeat: no-repeat;
    padding: 25px 6px 15px 13px;
    z-index: 99;
  }

  .login_form .username {
    width: 300px;
    left: 20px;
    top: 255px;
    position: static;
    margin-bottom: 25px;
    z-index: 99;
    margin-top: 10vh;
  }

  .login_form .password {
    width: 300px;
    left: inherit;
    right: 20px;
    top: 277px;
    position: static;
    margin-bottom: 25px;
    z-index: 99;
  }

  .login_form .login_btn {
    width: 170px;
    height: 47px !important;
    left: 20px;
    position: static;
    margin-bottom: 10px;
    padding: 8px 5px 3px 46px;
    background-image: url(../images/login_btn_bg.png);
    left: 20px;
    top: 390px;
    z-index: 99;
  }

  .login_form .forgotpassword {
    width: auto;
    left: 20px;
    top: 446px;
    font-size: 14px;
    position: static;
    z-index: 99;
  }

  .form__field:placeholder-shown~.form__label {
    top: 30px !important;
    left: 20px;
  }

  .login_bg label,
  .form__field:focus~.form__label {
    top: 20px !important;
    left: 15px !important;
  }

  .login_form input {
    width: 300px;
    height: 70px;
    background-size: 100%;
  }

  .form_logo {
    display: block;
    width: 80px !important;
    margin-left: 0 !important;
    position: absolute;
    left: 15px;
    top: 10px;
    z-index: 99;
  }

  .form_logo img {
    width: 100%;
    margin-top: 0px !important;
  }

  .ReactModalPortal .ReactModal__Content {
    padding: 20px 15px !important;
    width: 100%;
  }

  .comments_popup .post_comment {
    margin-bottom: 0px !important;
  }

  .modal-dialog {
    margin-top: 20px;
  }

  .forgotpassword_popup .col-6 {
    width: 100% !important;
    padding: 15px;
  }

  .comments_popup .popup_close {
    right: 6px;
    top: 0px;
  }

  .wrapper {
    width: 90% !important;
  }

}

@media (max-width:375px) {
  .auto_block {
    height: 90vh !important;
    padding: 0 10px 0px 10px !important;
  }

  .profile {
    width: 30px !important;
    height: 30px !important;
    font-size: 27px;
  }

  .profile img {
    width: 30px;
    height: 30px;
  }

  .post_hed {
    padding: 10px;

  }

  .post_logo {
    width: 45px;
    margin-top: -70px;
  }

  .hed_link {
    padding: 4px 5px;
    font-size: 10px;
    margin: 0 2px;
  }

  .hed_link .icon {
    width: 16px;
    margin-right: 5px;
  }

  .hed_link .icon img {
    width: 16px;
  }
}

@media (max-width:500px) {

  /* .auto_block {
    height: 580px;
    padding: 50px 25px 90px 25px;
  } */
  .terms {
    bottom: inherit !important;
    width: 76% !important;
    align-items: center;
    top: 670px;
  }

  .terms input {
    width: 10px;
    height: 10px;
    padding: 0px;
  }

  .terms label {
    margin-left: 6px !important;
    top: 0 !important;
  }

  .terms2 {
    bottom: inherit !important;
    width: 76% !important;
    align-items: center;
    top: 660px;
  }

  .terms2 input {
    width: 10px;
    height: 10px;
    padding: 0px;
  }

  .terms2 label {
    margin-left: 6px !important;
    top: 0 !important;
  }

  .comments_popup .comment_wrp .comment_details {
    min-width: 100%;
  }

  /* .auto_block .post_block {  width: 100% !important;}
  .profile { top: 115px;  right: 3px;} */
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v127/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.ReactModal__Content {
  background: transparent !important;
  border: none !important;
  padding: 0px 150px !important;
}

.ReactModal__Overlay {
  background: #000000d9 !important;
  z-index: 999;
}

.error {
  font-size: 12px;
  color: red;
  margin-top: 5px;
  margin-bottom: 0px;
}

.clm_alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px !important;
}

.clm_alert .material-icons {
  display: block;
  font-size: 18px;
  cursor: pointer;
}

.clm_alert a {
  text-decoration: none;
  color: #842029;
}

.wrapper {
  width: 1020px;
  margin: 0 auto;
}

.logo1 {
  width: 150px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.flex {
  display: flex;
}

.form_logo {
  width: 110px;
  margin-left: 10%;
}

.form_logo img {
  width: 100%;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* .addmeme { background-image: url(../images/main_bg.jpg); background-attachment: fixed; background-size: 100%; height: 100vh;} */


.page {
  background-image: none !important;
  background-color: #472021;
  color: #fff;
}

.page .meme_header {
  background-color: transparent;
  padding: 0px 15px;
}

.page .meme_header .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page .meme_header .logo1 {
  max-width: 100px;
  max-height: 45px;
  width: auto;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.page .meme_header h2 {
  margin-left: 0;
  margin-bottom: 0px;
  color: #ffffff;
  text-shadow: none;
}

.addmeme .content {
  padding-top: 15px;
  overflow: inherit;
}

.addmeme_form {
  margin-bottom: 15px;
}

a {
  color: #610536 !important;
}

.content {
  height: calc(100vh);
  overflow: auto;
  background-image: url(../images/about-bg.png);
  background-position: center;
}

.content .content_logo {
  width: 150px;
  margin: 0 auto;
  display: block;
  margin-top: 50px;
  margin-bottom: 30px;
}

.content .content_logo img {
  width: 100%;
}

.content h1 {
  color: #f3ba51;
  font-size: 32px;
  margin-bottom: 20px;
}

.addmeme {
  background-image: url(../images/about-bg.png) !important;
  background-position: center;
  height: 100%;
  position: relative;
  background-size: cover;
}

.addmeme .content {
  background-image: none !important;
}

.addmeme .addmeme_form .flex {
  justify-content: space-between;
}

.addmeme .addmeme_form input {
  width: 100%;
  border: solid 1px #a0a0a0;
  color: #222;
  background-color: #ebebeb;
  border-radius: 3px;
  padding: 7px 10px;
  font-size: 14px;
}

.addmeme .addmeme_form input::-webkit-input-placeholder {
  color: #636363;
}

.addmeme .addmeme_form input:-ms-input-placeholder {
  color: #636363;
}

.addmeme .addmeme_form input::placeholder {
  color: #636363;
}

.addmeme .form-field1 {
  width: 30%;
}

.addmeme .form-field2 {
  width: 67%;
  margin-left: 5px;
}

.addmeme .image_editer {
  margin-top: 15px;
  margin-bottom: 60px;
  display: contents;
}

.addmeme .btns {
  margin-top: 15px;
}

.addmeme .btns .btn {
  padding: 7px 20px;
  font-size: 14px;
  color: #fff;
  border-radius: 5px;
  border: none;
  text-decoration: none;
}

.addmeme .btns .btn:hover {
  text-decoration: none;
}

.addmeme .btns .submit {
  color: #333333 !important;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #d5d6d8;
  padding: 5px 20px;
  border-radius: 30px;
}

.addmeme .btns .cancel {
  color: #333333 !important;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #d5d6d8;
  padding: 5px 20px;
  border-radius: 30px;
  margin-left: 5px;
}

.addmeme .btns a:focus {
  box-shadow: none;
}



.form__group {
  position: relative;
  padding: 0;

}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #d2d2d2;
  outline: 0;
  font-size: 14px !important;
  color: #212121;
  background: transparent;
  transition: border-color 0.2s;
  padding-top: 10px;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown~.form__label {
  color: #c17435;
  font-size: 14px;
  cursor: text;
  top: 5px;
  display: block !important;
}

.login_bg label,
.form__field:focus~.form__label {
  position: absolute;
  top: 0;
  display: block !important;
  transition: 0.2s;
  font-size: 10px;
  color: #8d501f;
}

.form__field:focus~.form__label {
  color: #8d501f;
}

.form__field:focus {
  padding-bottom: 6px;

}

.meme_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.meme_header h2 {
  margin-left: 15px;
  font-weight: bold;
  color: #841212;
  text-shadow: 1px 1px 1px #969696;
}

.meme_header .backBtn {
  color: #fff !important;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.meme_header .backBtn .material-icons {
  font-size: 18px;
}

.post_likeblock .like span {
  font-size: 18px;
}

.tui-image-editor-container .tui-image-editor-header-buttons button {
  background-color: #ff725e !important;
  border: 1px solid #ff725e !important;
  border-radius: 5px !important;
}

.tui-image-editor-container .tui-image-editor-header-buttons div {
  border-radius: 5px !important;
}

.btns a:focus {
  box-shadow: none;
}

.dropdown-menu {
  background-color: #e1e4e9 !important;
  border: solid 1px #fff !important;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.dropdown-item {
  color: #333333 !important;
  display: flex !important;
  align-items: center;
  padding: 7px 1rem;
}

.dropdown-item:hover,
.dropdown-item:visited,
.dropdown-item:hover:focus {
  text-decoration: none;
}

.dropdown-item .material-icons {
  color: #720005;
  margin-right: 10px;
}

.dropdown-toggle::after {
  display: none !important;
}

.profile .dropdown-menu img {
  width: 25px !important;
  height: 25px !important;
  margin-right: 5px !important;
}

.terms label {
  position: inherit;
  color: #fdf748;
  margin-left: 20px;
}

.terms a {
  color: #fdf748 !important;
}

.terms a:hover {
  text-decoration: none;
}

.terms {
  display: flex;
  position: absolute;
  bottom: 20%;
  width: 50%;
  left: 17%;
}

.terms2 label {
  position: inherit;
  color: #fdf748;
  margin-left: 20px;
}

.terms2 {
  display: flex;
  position: absolute;
  bottom: 24%;
  width: 50%;
  left: 17%;
}


@media (max-width:1280px) and (min-width: 1000px) {
  .auto_block {
    width: 67% !important;
    padding: 2% 6% 3% 12% !important;
    background-size: 101% !important;
  }

  .post_logo {
    margin-left: -5px;
  }

  .post_hed {
    padding: 10px;
  }

  .addmeme_footer {
    margin-top: 60px;
  }

}

@media (max-width:1920px) and (min-width: 1800px) {
  .profile img {
    width: 48px;
    height: 48px;
  }

  .blink {
    width: 48px;
    height: 48px;
  }

  .profile {
    top: 24.2vh;
    right: 2.5vh;
  }
}

@media (max-width:1490px) and (min-width: 1400px) {
  .profile img {
    width: 40px;
    height: 40px;
  }

  .blink {
    width: 40px;
    height: 40px;
  }

  .profile {
    top: 25.8vh;
    right: 2.1vh;
  }

  .auto_block {
    padding: 7% 8% 12% 12%;
  }

  .trending {
    top: 7vh;
    right: 152px;
  }

  .profile .dropdown-toggle::after {
    bottom: -0.7vh;
  }
}

@media (max-width:1390px) and (min-width: 1300px) {
  .auto_block {
    background-size: 101%;
  }

  .trending {
    right: 152px;
    font-size: 20px;
    padding: 0 10px;
  }

  .profile img {
    width: 35px;
    height: 35px;
  }

  .blink {
    width: 35px;
    height: 35px;
  }

  .profile {
    top: 22.7vh;
    right: 4.3vh;
  }

  /* .profile .dropdown-toggle::after { bottom: -0.52vh;} */
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tui-image-editor-container .tui-image-editor-header-logo {
  display: none;
}

.tui-image-editor-container .color-picker-control .tui-colorpicker-palette-button {
  font-size: 4px !important;
  border-radius: 50% !important;
  height: 16px;
}

.tui-image-editor-container .color-picker-control {
  width: auto !important;
}

.rs-loader-inverse .rs-loader-backdrop {
  background: #1f0c0cd4 !important;
}

.rs-loader .rs-loader-content {
  color: #fff !important;
  font-size: 16px !important;
}

.rs-loader .rs-loader-spin,
.rs-loader .rs-loader-spin:after,
.rs-loader .rs-loader-spin:before {
  width: 30px !important;
  height: 30px !important;
}